import './Footer.sass';
import Logo from '../../../components/Logo.js';
import SocialBar from '../../../components/SocialBar/SocialBar.js';
import Reveal from '../../../components/Reveal.js';
import data from './data.json';
import useLang from '../../../hooks/useLang.js';

function Footer() {
  const {lang} = useLang();
  return (
    <footer className='Footer'>
      <div className='container'>
        <div className='links'>
          <div className='info'>
            <Reveal type="slide"><Logo /></Reveal>
            <Reveal type="slide"><p>danylokhobta@gmail.com</p></Reveal>
            <Reveal type="slide"><p>{data[lang].info}</p></Reveal>
          </div>
          <div className='media'>
            <Reveal type="slide"><span>{data[lang].media}</span></Reveal>
            <Reveal type="slide"><SocialBar iconSize={32} /></Reveal>
          </div>
        </div>
        <div className='copyright'>
          <Reveal type="slide"><p>© Copyright 2024. Made by Danylo</p></Reveal>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
